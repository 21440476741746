import Logo from "../../assets/img/logo.jpg";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Button from '../button/Button';
import {} from '../../assets/css/header.css'



const Header = () => {
  
  return (
    <>
    {/* <!-- ======= Header ======= --> */}
    <nav className="navbar navbar-expand-lg py-3">
      <div className="container">
        <NavLink className="navbar-brand" to={'/'}>AFRICADOCNEW</NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" to={'business'}>Créer une entreprise</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={'manage'}>Gérer vôtre entreprise</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={'follow'}>Accompagnement</NavLink>
            </li>
          </ul>
            <div className="d-none d-sm-block">
              <Button primary={true} label="Espace client" routing="login" />
            </div>
        </div>
        
      </div>
    </nav>
      {/* <header id="header">
        <div className="container d-flex justify-content-between align-items-center">
          <Link to={"/"}><img src={Logo} alt="Le logo de l'entreprise" className='logo'/></Link>
          <nav id="navbar" className="navbar">
            <ul>
              <li><NavLink className="dropdown" to="#"><span>Création d'une entreprise</span> <i class="bi bi-chevron-down"></i>
                <ul className="dropdown-active">
                  <li><NavLink to={"individuel"}>Créer une entreprise individuel</NavLink></li>
                  <li><NavLink to={"sarl"}>Créer une SARL</NavLink></li>
                  <li><NavLink to={"sarlu"}>Créer une SARLU</NavLink></li>
                  <li><NavLink to={"sas"}>Créer une SAS</NavLink></li>
                  <li><NavLink to={"sasu"}>Créer une SASU</NavLink></li>
                  <li><NavLink to={"ong"}>Créer une ONG</NavLink></li>
                  <li><NavLink to={"sci"}>Créer une SCI</NavLink></li>
                  <li><NavLink to={"startup"}>Créer une STARTUP</NavLink></li>
                  <li><NavLink to={"filiale"}>Créer une FILIALE</NavLink></li>
                </ul>
              </NavLink></li>
              <li><NavLink className="dropdown" to="#"><span>Gestion d'une entreprise</span> <i class="bi bi-chevron-down"></i>
                  <ul className="dropdown-active">
                    <li><NavLink to={""}>Domiciliez votre entreprise</NavLink></li>
                    <li><NavLink to={""}>Rédaction de CDD</NavLink></li>
                    <li><NavLink to={""}>Rédaction de CDI</NavLink></li>
                    <li><NavLink to={""}>Contrat de bail</NavLink></li>
                  </ul>
              </NavLink></li>
              <li><NavLink className="dropdown" to="#"><span>Accompagnement</span> <i class="bi bi-chevron-down"></i>
                  <ul>
                    <li><NavLink to={""}>Comptabilité</NavLink></li>
                    <li><NavLink to={""}>Gestion de le paie</NavLink></li>
                    <li><NavLink to={""}>Déclaration CNPS</NavLink></li>
                  </ul>
              </NavLink></li>
              <li><NavLink className="dropdown" to="#"><span>Etat civile</span> <i class="bi bi-chevron-down"></i>
                <ul>
                  <NavLink to={""}>Actes de Naissance</NavLink>
                  <NavLink to={""}>Actes de Mariage</NavLink>
                  <NavLink to={""}>Actes de décès</NavLink>
                  <NavLink to={""}>Certifications de Document</NavLink>
                  <NavLink to={""}>Légalisation De Signature</NavLink>
                </ul>
              </NavLink></li>
              <li><NavLink className="dropdown" to="#"><span>Acte de justice</span> <i class="bi bi-chevron-down"></i>
                <ul>
                  <NavLink to={""}>Certificats de Nationalité Ivoirienne</NavLink>
                  <NavLink to={""}>Cassier Judiciaires</NavLink>
                </ul>
              </NavLink></li>
              <li><NavLink to={""}><NavLink className="nav-link scrollto">Contact</NavLink></NavLink></li>
              <li><NavLink to={"login"}><NavLink className="getstarted scrollto">Espace client</NavLink></NavLink></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header> */}
    </>
  );
}

export default Header;