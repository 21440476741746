import { HiArrowSmRight } from "react-icons/hi"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer className='container-fluid'>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <h3>A propos</h3>
                    <p>Situté à Abidjan Non loi de la pharmacie sainte athanse</p>
                    <p>Téléphone : </p>
                    <p>Email</p>
                    <p>N° RCCM : </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <h3>Nos Services</h3>
                    <ul>
                        <li><HiArrowSmRight/> <Link to='/'>Accueil</Link></li>
                        <li><HiArrowSmRight/> <Link to='business'>Création d'entreprise</Link></li>
                        <li><HiArrowSmRight/> <Link to='manage'>Gestion d'entreprise</Link></li>
                        <li><HiArrowSmRight/> <Link to='follow'>Accompagement</Link></li>
                        <li><HiArrowSmRight/> <Link to='business'>Etat civil</Link></li>
                        <li><HiArrowSmRight/> <Link to='business'>Actes de justices</Link></li>
                    </ul>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <h3>AFRICADOC</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>        
            </div>
        </div>
    </footer>
  )
}

export default Footer
