import styled, { css,keyframes } from 'styled-components'

const Container = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

`


const styleLoader = keyframes`
    to{transform: rotate(.5turn)}
`

export const LoaderStyle = styled.div`
  width:50px;
  height:50px;
  --c:radial-gradient(farthest-side,rgba(90,42,100,1) 92%,#0000);
  background: 
    var(--c) 50% 0,
    var(--c) 50% 100%,
    var(--c) 100% 50%,
    var(--c) 0    50%;
  background-size:12px 12px;
  background-repeat:no-repeat;
  animation: ${styleLoader} 1s infinite;
 
`;

function Loader() {
  return (
    <Container className=''>
        <LoaderStyle></LoaderStyle>
    </Container>
  )
}

export default Loader