function FormChangementDeGerant() {
  return (
    <div className="container-fluid my-5">
        <div className="container">
            <div className="col-lg-6 col-md-6 col-sm-12 offset-lg-3 offset-md-3">
                <form action="" className="form bg-white shadow p-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group mb-3">
                            <label 
                             htmlFor="nom" 
                             className="form-label"
                            >
                                Nom
                            </label>
                            <input
                             type="text" 
                             name="nom" 
                             id="nom" 
                             className="form-control" 
                             required
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group mb-3">
                            <label htmlFor="prenom" className="form-label">Prénoms</label>
                            <input
                             type="text" 
                             name="prenom" 
                             id="prenom" 
                             className="form-control" 
                             required
                            />
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                         type="text" 
                         name="email" 
                         id="email"
                         className="form-control" 
                         required
                         />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="nom-entreprise" className="form-label">Nom de l'entreprise</label>
                        <input
                         type="text" 
                         name="nom-entreprise" 
                         id="nom-entreprise" 
                         className="form-control" 
                         required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="numero-rccm" className="form-label">Numero RCCM</label>
                        <input
                         type="text"
                         name="numero-rccm" 
                         id="numero-rccm" 
                         className="form-control" 
                         required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="siege-social" className="form-label">Siège social</label>
                        <input
                         type="text" 
                         name="siege-social" 
                         id="siege-social" 
                         className="form-control" 
                         required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="forme-juridique" className="form-label">Forme Juridique</label>
                        <select 
                         name="forme-juridique" 
                         id="forme-juridique" 
                         className="form-select shadow-none"
                        >
                            <option value="" selected disabled>Veuillez sélectionner</option>
                            <option value="SA">SA</option>
                            <option value="SAS">SAS</option>
                            <option value="SARL">SARL</option>
                            <option value="SASU">SASU</option>
                            <option value="SARLU">SARLU</option>
                            <option value="GIE">GIE</option>
                            <option value="SNC">SNC</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="sujet" className="form-label">
                            Sur quel sujet voulez-vous apporter une modification ?
                        </label>
                        <select 
                         name="sujet" 
                         id="sujet" 
                         className="form-select shadow-none"
                        >
                            <option value="" selected disabled>Veuillez sélectionner</option>
                            <option value="La dénomination">La dénomination</option>
                            <option value="Le gérant">Le gérant</option>
                            <option value="Le capital social">Le capital social</option>
                            <option value="Les activités">Les activités</option>
                            <option value="Siège social">Siège social</option>
                            <option value="Augmenter le capital"></option>
                        </select>
                    </div> 
                    <div className="form-group mb-3">
                        <label htmlFor="description" className="form-label">
                            Décrivez la modification que vous voulez apporter
                        </label>
                        <textarea className="form-control shadow-none" name="description" id="description" cols="30" rows="3"></textarea>
                    </div>
                    <button className="btn btn-success">Soumettre</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default FormChangementDeGerant