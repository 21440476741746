import styled, { css } from 'styled-components'

export const StyleButton = styled.button`
  background: var(--primary-color);
  border-radius: 3px;
  border: 2px solid var(--secondary-color);
  color: #FFF;
  margin: 0.5em 1em;
  padding: 0.25em 1em;
  transition: all 0.4s;

  ${props => props.$primary && css`
    background-color: var(--secondary-color)!important;
    color: white;
  `}
 
`;