import styled from "styled-components";

export const StyleButtonHelpWhatsapp = styled.button`
    padding: 0;
    position: fixed;
    right: 0;
    top: 500px;
    padding: 0.5rem 1rem;
    background-color: green;
    border: none;
    border-radius: 10px;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    transition: 0.4s;
    /* transform: rotate(90deg); */

    &:hover{
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    }
`