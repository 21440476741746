import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/button/Button'


const Signup = () => {
  return (
    <div className='container-fluid py-5'>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 offset-lg-3 offset-md-3">
                    <h1 className='mb-3'>Créer votre espace client</h1>
                    <form action="#" method="post">
                        <div className="form-group mb-3">
                            <label htmlFor="username" className="form-label">Nom utilisateur</label>
                            <input type="text" name='username' id='username' className='form-control' />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" name='email' id='email' className='form-control' />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="password" className="form-label">Mot de passe</label>
                            <input type="password" name='password' id='password' className='form-control shadow-none' />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="password-confirm" className="form-label">Confirmer votre mot de passe</label>
                            <input type="password" name='password-confirm' id='password-confirm' className='form-control shadow-none' />
                        </div>
                        <Button label="S'inscrire" primary/>
                        {/* <button type="submit" className='btn'>S'inscrire</button> */}
                        <Link to={"/login"}>J'ai un compte client, je me connecte</Link>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Signup