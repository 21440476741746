export const DBFaq = [
    {
        id: 1,
        question: "Ou êtes vous située?",
        answer: "Nous sommes à la rivéira 2 non loin de la pharmacie saint athanse"
    },
    {
        id: 2,
        question: "Avez vous combien d'année dans le domaine l'activités de création d'entreprise?",
        answer: "Nous sommes à la rivéira 2 non loin de la pharmacie saint athanse"
    },
    {
        id: 3,
        question: "Avez vous d'autre agence hors de la Côte d'Ivoire?",
        answer: "Nous sommes à la rivéira 2 non loin de la pharmacie saint athanse"
    },
    {
        id: 4,
        question: "Quelle sont les avantages que je gagne si je crée mon entreprise chez AFRICADOCNEW",
        answer: "Nous sommes à la rivéira 2 non loin de la pharmacie saint athanse"
    },
    {
        id: 5,
        question: "Combien côute les services de suivie comptable?",
        answer: "Nous sommes à la rivéira 2 non loin de la pharmacie saint athanse"
    },
    {
        id: 6,
        question: "Puisse je avoir un devis pour la gestion de mon RH?",
        answer: "Nous sommes à la rivéira 2 non loin de la pharmacie saint athanse"
    },
    {
        id: 7,
        question: "Pouvez vous me parlez un peu de votre entreprise?",
        answer: "Nous sommes à la rivéira 2 non loin de la pharmacie saint athanse"
    },
]