import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  Route, 
  RouterProvider 
} from 'react-router-dom'

// Page non Lazy
import Signup from './pages/Signup';

import Loader from './components/Loader/Loader';



// Form manage 
import FormChangementDeGerant from './components/form/FormChangementDeGerant';



// Page Lazy
const LazyHome = React.lazy(() => import('./pages/Home'))
const LazyBussines = React.lazy(() => import('./pages/Business'))
const LazyManagement = React.lazy(() => import('./pages/Management'))
const LazyFollow = React.lazy(() => import('./pages/Follow'))
const LazyLogin = React.lazy(()=> import('./pages/Login'))
const LazyPageNotFound = React.lazy(() => import('./pages/PageNotFound'))
const LazyBusinessForm = React.lazy(()=> import('./pages/CreateBusinessForm'))
const LazySas = React.lazy(() => import('./pages/businessTypes/Sas'))
const LazySarl = React.lazy(() => import('./pages/businessTypes/Sarl'))
const LazySarlu = React.lazy(() => import('./pages/businessTypes/Sarlu'))
const LazyIndividuel = React.lazy(() => import('./pages/businessTypes/Individuel'))
const LazySasu = React.lazy(() => import('./pages/businessTypes/Sasu'))
const LazyOng = React.lazy(() => import ('./pages/businessTypes/Ong'))
const LazyFondation = React.lazy(() => import('./pages/businessTypes/Foundation'))
const LazyAssociation = React.lazy(() => import('./pages/businessTypes/Association'))
const LazySci = React.lazy(() => import('./pages/businessTypes/Sci'))
const LazyScoops = React.lazy(() => import('./pages/businessTypes/Scoops'))

// Lazy Modification d'entreprise
const LazyChangementDeGerant = React.lazy(() => import('./pages/manages/businessModification/ChangementDeGerant'))
const LazyModificationActivity = React.lazy(() => import('./pages/manages/businessModification/ActiviteModifier'))
const LazyCessionDePart = React.lazy(() => import('./pages/manages/businessModification/PartCession'))
const ChangementDeNom = React.lazy(() => import('./pages/manages/businessModification/ChangementDeNom'))
const AugmenterCapital = React.lazy(() => import('./pages/manages/businessModification/AugmenterCapital'))
const ChangementAdresse = React.lazy(() => import('./pages/manages/businessModification/ChangementAdresse'))
const ModificationDeStatu = React.lazy(() => import('./pages/manages/businessModification/ModificationDeStatu'))

// Lazy depot de marque
const LazyDepotMarque = React.lazy(() => import('./pages/manages/marque/DepotDeMarque'))
const LazyRechercheAnteriorite = React.lazy(() => import('./pages/manages/marque/RechercheAnteriorite'))

// Lazy contrat
const LazyRedactionDeCDD = React.lazy(() => import('./pages/manages/contrat/RedactionCDD'))
const LazyRedactionDeCDI = React.lazy(() => import('./pages/manages/contrat/redactionCDI'))
const LazyContratDeBail = React.lazy(() => import('./pages/manages/contrat/ContratDeBail'))

// Lazy Recouvrement de creance
const LazyReccuppererSesImpayes = React.lazy(() => import('./pages/manages/creance/Impayes'))
const LazyMiseEnDemeure = React.lazy(() => import('./pages/manages/creance/MiseEnDemeure'))

// Lazy Fermer son Entreprise 
const LazyRadiation = React.lazy(() => import('./pages/manages/fermerSonEntreprise/Radiation'))
const LazyMiseEnSommeil = React.lazy(() => import('./pages/manages/fermerSonEntreprise/MiseEnSommeil'))


// Lazy Accompagnement
const LazySuivieComptable = React.lazy(() => import('./pages/follow/SuivieComptable'))
const LazyGestionDesPaies = React.lazy(() => import('./pages/follow/GestionDesPaies'))
const LazyDeclarationCnps = React.lazy(() => import('./pages/follow/DeclarationCnps'))
const LazyLicencierUnSala = React.lazy(() => import('./pages/follow/LicencierUnSalarie'))


// ROOT
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App/>}>

      <Route index element={
        <React.Suspense fallback={<Loader/>}>
          <LazyHome/>
        </React.Suspense>
      }></Route>

      <Route path='login' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyLogin/>
        </React.Suspense>
      } ></Route>

      <Route path='business' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyBussines/>
        </React.Suspense>
      }>
      </Route>
      
      <Route path='manage' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyManagement/>
        </React.Suspense>
      }>
      
      </Route>
      <Route path='follow' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyFollow/>
        </React.Suspense>
      }>
      </Route>
      
      <Route path='form-business/:forme' element={
          <React.Suspense fallback={<Loader/>}>
            <LazyBusinessForm/>
          </React.Suspense>
        }>
      </Route>
      
      <Route path='*' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyPageNotFound/>
        </React.Suspense>
      }></Route>
      
      <Route path='individuel' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyIndividuel/>
        </React.Suspense>
      }></Route>
      
      <Route path='sarl' element={
        <React.Suspense fallback={<Loader/>}>
          <LazySarl/>
        </React.Suspense>
      }></Route>

      <Route path='sarlu' element={
        <React.Suspense fallback={<Loader/>}>
          <LazySarlu/>
        </React.Suspense>
      }></Route>
      
      <Route path='sas' element={
        <React.Suspense fallback={<Loader/>}>
          <LazySas/>
        </React.Suspense>
      }></Route>

      <Route path='sasu' element={
        <React.Suspense fallback={<Loader/>}>
          <LazySasu/>
        </React.Suspense>
      }>

      </Route>
      <Route path='ong' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyOng/>
        </React.Suspense>
        }></Route>

      <Route path='fondation' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyFondation/>
        </React.Suspense>
      }></Route>

      <Route path='association' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyAssociation/>
        </React.Suspense>
      }></Route>

      <Route path='sci' element={
        <React.Suspense fallback={<Loader/>}>
          <LazySci/>
        </React.Suspense>
      }></Route>

      <Route path='scoops' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyScoops/>
        </React.Suspense>
      }></Route>

      {/* Manage business */}
      <Route path='changement-de-gerant' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyChangementDeGerant/>
        </React.Suspense>
      }></Route>

      <Route path='form-changement-de-gerant' element={<FormChangementDeGerant/>}></Route>
      
      <Route path='cession-de-part' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyCessionDePart/>
        </React.Suspense>
      }></Route>
      
      <Route path='modification-d-activites' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyModificationActivity/>
        </React.Suspense>
      }></Route>

      <Route path='changement-de-nom' element={
        <React.Suspense fallback={<Loader/>}>
          <ChangementDeNom/>
        </React.Suspense>
      }></Route>

      <Route path='augmentation-de-capital' element={
        <React.Suspense fallback={<Loader/>}>
          <AugmenterCapital/>
        </React.Suspense>
      }></Route>

      <Route path='changement-d-adresse' element={
        <React.Suspense fallback={<Loader/>}>
          <ChangementAdresse/>
        </React.Suspense>
      }></Route>

      <Route path='modification-de-statu' element={
        <React.Suspense fallback={<Loader/>}>
          <ModificationDeStatu/>
        </React.Suspense>
      }></Route>

      <Route path='depot-de-marque' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyDepotMarque/>
        </React.Suspense>
      }></Route>

      <Route path='recherche-d-anteriorite' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyRechercheAnteriorite/>
        </React.Suspense>
      }></Route>  

      <Route path='redaction-de-cdd' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyRedactionDeCDD/>
        </React.Suspense>
      }></Route> 

      <Route path='redaction-de-cdi' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyRedactionDeCDI/>
        </React.Suspense>
      }></Route>

      <Route path='contrat-de-bail' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyContratDeBail/>
        </React.Suspense>
      }></Route>

      <Route path='reccuperer-ses-impayes' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyReccuppererSesImpayes/>
        </React.Suspense>
      }></Route>

      <Route path='mise-en-demeure-de-payer' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyMiseEnDemeure/>
        </React.Suspense>
      }></Route>

      <Route path='radiation' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyRadiation/>
        </React.Suspense>
      }></Route>

      <Route path='mise-en-sommeil' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyMiseEnSommeil/>
        </React.Suspense>
      }></Route>

      <Route path='suivie-comptable' element={
        <React.Suspense fallback={<Loader/>}>
          <LazySuivieComptable/>
        </React.Suspense>
      }></Route>
      
      <Route path='gestion-de-la-paie' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyGestionDesPaies/>
        </React.Suspense>
      }></Route>
      
      <Route path='declaration-cnps' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyDeclarationCnps/>
        </React.Suspense>
      }></Route>

      <Route path='licencier-un-salarie' element={
        <React.Suspense fallback={<Loader/>}>
          <LazyLicencierUnSala/>
        </React.Suspense>
      }></Route>
      
      <Route path='signup' element={<Signup/>}></Route>
    </Route>
  )
)


// Rendre dans le dom
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
