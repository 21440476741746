import styled from "styled-components";

export const StyledButtonTelephone = styled.button`
    padding: 0.5rem 1rem;
    position: fixed;
    right: 0;
    top: 550px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 10px;

`