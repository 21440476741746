import { useState } from 'react'
import {AiOutlineClose} from 'react-icons/ai'

function ChatBox({data, handleClose}) {
    const [active, setActive] = useState(false)

    const handleActive = () => {
        return setActive(() => !active)
    }

  return (
    <div
     className="card shadow"
     style={
        {
            position: 'fixed',
            right: '50px',
            bottom: '0',
            width: '25%',
            height: '400px',
            zIndex: '1',
        }
     }
    >
        <div className="body">
            <div className="shadow rounded p-3 d-flex justify-content-between align-items-center">
                <b>Nouveau message</b>
                <button onClick={handleClose}><AiOutlineClose/></button>
            </div>
            <div style={{ overflowY: 'scroll', height: '280px',scrollBehavior: 'smooth'}} className="content py-3 px-2">
                { data.map((faq =>{
                    return (
                        <>
                            <button
                            onClick={handleActive}
                            className='btn btn-outline-dark my-3 w-100'
                            >
                                {faq.question}
                            </button>
                            <p
                             className={ active ? 'd-block' : 'd-none'}
                            >
                                { faq.answer }
                            </p>
                        </>
                    )}))
                }
            </div>
        </div>
        <div className="footer shadow position-absolute bottom-0 w-100">
            <form action="" method="post">
                <div className="input-group">
                    <input
                     type="text" 
                     id="messange" 
                     name="messange" 
                     placeholder="En quoi nous pouvons vous aidez? " 
                     aria-placeholder="En quoi nous pouvons vous aidez?" 
                     className="form-control"
                    />
                    <button
                     type="submit" 
                     name="send" 
                     id="send" 
                     className="btn btn-dark"
                    >
                        Envoyer
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default ChatBox