import Header from './components/header/Header';
import { Link, Outlet } from 'react-router-dom';
import Footer from './components/footer/Footer';

import './assets/css/formOrder.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import HelpWhatsapp from './components/help/whatsapp/HelpWhatsapp';
import Telephone from './components/help/telephone/Telephone';
import ButtonChatBoot from './components/help/ButtonChatBoot';
import { useState } from 'react';
import ChatBox from './components/ChatBox';
import { DBFaq } from './utility/database/DBfaq';


function App() {
  const [show, setShow] = useState(false)

  const handleClick = () => {
    return setShow(()=> !show)
  }

  const handleClose = () => {
    return setShow(false)
  }


  const getYear = () => {
    let date = new Date()
    return date.getFullYear()
  }

  return (
      <div className="App">
        <Header></Header>
        <main>
          <Outlet/>
        </main>
        <Footer></Footer>
        <div className="container-fluid" id='copyrigth'>
          <div className='container'>
            <div className='row'>
              <p>Copyrigth 2023 - {getYear()} <Link to='https://www.mozargroup.org' translate='en' target='_blank' hrefLang='en'>Create By Mozar Group</Link></p>
            </div>
          </div>
        </div>
        <HelpWhatsapp/>
        <Telephone/>
        <ButtonChatBoot handleClick={handleClick}/>
        { show && (
            <ChatBox data={DBFaq} handleClose={handleClose}/>
        )}
      </div>
  );
}

export default App;