import styled from "styled-components";

export const StyledButtonChatBoot = styled.button`
 padding: 0.5rem 1rem;
    position: fixed;
    right: 0;
    top: 600px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 10px;
`