import { Link, NavLink, useNavigate } from 'react-router-dom';
import { StyleButton } from "./button.styled"


function Button(props) {
  const navigate = useNavigate();
  return <>
    <StyleButton onClick={()=>navigate(props.routing)} type="button" $primary={props.primary}>{ props.label }</StyleButton>
  </>
}

export default Button